import React from 'react';
import './sessions.css';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import Bg from '../components/parallax';

const timelineData = [
  {
    text: 'Road to Smart India Hackathon: Insights and Strategies',
    date: 'September 2024',
    category: {
      tag: 'Hackathon',
      color: '#000',
    },
    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_sih2024-hackathon-innovation-activity-7238499982238986241-CnXk?utm_source=share&utm_medium=member_desktop',
      text: 'Check it out here',
    },
  },
  {
    text: 'Blockchain 101 Session, conducted by Soureesh Patil',
    date: 'November 2021',
    category: {
      tag: 'Blockchain',
      color: '#000',
    },

    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_blockchain-bitcoin-techcommunity-activity-6866625239015669761-u1do?utm_source=linkedin_share&utm_medium=member_desktop_web',
      text: 'Check it out here',
    },
  },

  {
    text: 'Collaboration with Mindspark for 5.0 Hackathon',
    date: 'October 2021',
    category: {
      tag: 'Hackathon 5.0',
      color: '#000',
    },

    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_react-javascript-reactnative-activity-6789180632271491072-2ydE',
      text: 'Check it out here',
    },
  },

  {
    text: 'MERN Stack Hands-on Session, conducted by Shreya Vaidya and Sameer',
    date: 'October 2021',
    category: {
      tag: 'Mern Stack',
      color: '#000',
    },

    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_coding-mernstack-learning-activity-6855875374128087040-__pV?utm_source=linkedin_share&utm_medium=member_desktop_web',
      text: 'Check it out here',
    },
  },

  {
    text: 'Conducted Online Elections for Gymkhana and Gathering Secretary',
    date: 'August 2021',
    category: {
      tag: 'Online Elections',
      color: '#000',
    },

    link: {
      url: 'https://election.sdscoep.com',
      text: 'Check it out here',
    },
  },

  {
    text:
      'Resume Building and Placement preparation Session, conducted by Prasad Rathod, MTS @ VMware.',
    date: 'June 2021',
    category: {
      tag: 'Resume',
      color: '#000',
    },

    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_placements-internship-coding-activity-6812417193729388544-0l7x?utm_source=linkedin_share&utm_medium=member_desktop_web',
      text: 'Check it out here',
    },
  },

  {
    text: 'Hands-on workshop on React Native',
    date: 'April 2021',
    category: {
      tag: 'React-native',
      color: '#000',
    },

    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_react-javascript-reactnative-activity-6789180632271491072-2ydE',
      text: 'Check it out here',
    },
  },

  {
    text: 'Hands-on 2 day workshop on Docker',
    date: 'August 2020',
    category: {
      tag: 'Docker',
      color: '#000',
    },

    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_sds-docker-activity-6698936547816939520-HIe6',
      text: 'Check it out here',
    },
  },

  {
    text: 'Hands-on 2 day workshop on Redux by Savan Nahar',
    date: 'July 2020',
    category: {
      tag: 'Redux',
      color: '#000',
    },

    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_sds-redux-stayhome-activity-6668132591260061696-BKe1',
      text: 'Check it out here',
    },
  },

  {
    text: 'SDS Alumni Meet and QnA Session through Zoom',
    date: 'May 2020',
    category: {
      tag: 'SDS Alumni Meet',
      color: '#000',
    },

    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_sds-founders-stayhome-activity-6671114709388931072-Vouq',
      text: 'Check it out here',
    },
  },

  {
    text: 'Session on Progressive web app by Rohit Chaudhari',
    date: 'February 2020',
    category: {
      tag: 'Progressive Web',
      color: '#000',
    },

    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_pwa-progressivewebapp-sds-activity-6642815816691740672-9mKr',
      text: 'Check it out here',
    },
  },

  {
    text: 'Hands-on session on Nginx',
    date: '',
    category: {
      tag: 'Nginx',
      color: '#000',
    },

    // link: {
    //     url: '#',
    //     text: 'Check it out here' }
  },

  {
    text: 'Hands-on 4 day workshop on GraphQL and Gatsby.js',
    date: 'February 2020',
    category: {
      tag: 'GraphQL and Gatsby.js',
      color: '#000',
    },

    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_graphql-nginx-javascript-activity-6639886148330196992-olLZ',
      text: 'Check it out here',
    },
  },

  {
    text: 'Hands-on session on deploying projects live on AWS',
    date: 'January 2020',
    category: {
      tag: 'AWS',
      color: '#000',
    },

    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_we-officially-started-off-the-semester-with-activity-6627023514178289664-wUF5',
      text: 'Check it out here',
    },
  },

  {
    text: 'Hands-on 3 day workshop on MERN(Mongo Express React Node) stack',
    date: 'January 2020',
    category: {
      tag: 'MERN Stack',
      color: '#000',
    },

    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_sds-webdev-react-activity-6627873261965410304-Nooq',
      text: 'Check it out here',
    },
  },

  {
    text: 'Hands-on 2 day workshop on Flutter Development',
    date: 'November 2020',
    category: {
      tag: 'Flutter Development',
      color: '#000',
    },

    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_dart-flutter-sds-activity-6611886703294676992-ihWE',
      text: 'Check it out here',
    },
  },

  {
    text: 'Hands-on session on Android Development',
    date: '',
    category: {
      tag: 'Android Development',
      color: '#000',
    },

    // link: {
    //     url: '#',
    //     text: 'Check it out here' }
  },

  {
    text: 'Hands-on 3 day React lecture series',
    date: 'October 2019',
    category: {
      tag: 'React',
      color: '#000',
    },

    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_react-lifecycle-reactjs-activity-6597514831224496128-kQ-m',
      text: 'Check it out here',
    },
  },

  {
    text: 'Hands-on session on LAMP stack and working with AJAX',
    date: 'October 2019',
    category: {
      tag: 'Lamp Stack',
      color: '#000',
    },

    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_sds-webdevelopment-activity-6597097556621262848-tTfd',
      text: 'Check it out here',
    },
  },

  {
    text: 'Interactive session by Varad Ghodke on cracking GRE',
    date: '',
    category: {
      tag: 'Craking GRE',
      color: '#000',
    },

    link: {
      url: 'https://www.linkedin.com/in/varadghodake/',
      text: 'Varad Ghodke',
    },
  },

  {
    text: 'Talk by Ashish Tetali on the World of Finance',
    date: 'September 2019',
    category: {
      tag: 'World of Finance',
      color: '#000',
    },

    link: {
      url:
        'https://www.linkedin.com/posts/sdscoep_sds-finance-engineering-activity-6589744781105164288-B2cp',
      text: 'Check it out here',
    },
  },

  {
    text: 'Talks by Shradhit Subudhi & Parag Verma on Masters & GRE Prep',
    date: '',
    category: {
      tag: 'Masters And GRE Prep',
      color: '#000',
    },

    link: {
      url: 'https://www.linkedin.com/in/shradhit/',
      text: 'Shradhit Subudhi',
    },
  },
];

const TimelineItem = ({ data }) =>
  React.createElement(
    'div',
    { className: 'timeline-item' },
    React.createElement(
      'div',
      { className: 'timeline-item-content' },
      React.createElement(
        'span',
        { className: 'tag', style: { background: data.category.color } },
        data.category.tag,
      ),

      React.createElement('time', null, data.date),
      React.createElement('p', null, data.text),
      data.link &&
        React.createElement(
          'a',
          {
            href: data.link.url,
            target: '_blank',
            rel: 'noopener noreferrer',
          },

          data.link.text,
        ),

      React.createElement('span', { className: 'circle' }),
    ),
  );

const Timeline = () =>
  timelineData.length > 0 &&
  React.createElement(
    'div',
    { className: 'timeline-container' },
    timelineData.map((data, idx) =>
      React.createElement(TimelineItem, { data: data, key: idx }),
    ),
  );

const App = () =>
  React.createElement(
    React.Fragment,
    null,
    React.createElement(Navbar, null),
    React.createElement(Bg, null),
    React.createElement(
      'div',
      { className: 'main-content' },
      React.createElement('h1', null, 'Talks and Sessions'),
      React.createElement('p', { class: 'centered' }, ' '),
      React.createElement(Timeline, null),
      React.createElement(Footer, null),
    ),
  );

export default App;
